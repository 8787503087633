/** When your routing table is too long, you can split it into small modules **/

import Layout from "@/layout";

const adminRouter = [
  {
    path: "/users",
    component: Layout,
    redirect: "/users",
    children: [
      {
        name: "Users",
        path: "",
        component: () => import("@/views/pages/users/index"),
        meta: {
          title: "用户管理",
          icon: "iconfont icon-username",
        },
      },
    ],
  },
  {
    path: "/content",
    component: Layout,
    redirect: "/content/topics",
    name: "content",
    meta: {
      title: "内容管理",
      icon: "iconfont icon-topic",
    },
    children: [
      {
        name: "Topics",
        path: "topics",
        component: () => import("@/views/pages/topics/index"),
        meta: {
          title: "话题",
          icon: "iconfont icon-topic",
        },
      },
      {
        name: "Articles",
        path: "articles",
        component: () => import("@/views/pages/articles/index"),
        meta: {
          title: "文章",
          icon: "iconfont icon-article",
        },
      },{
        name: "Animation",
        path: "animation",
        component: () => import("@/views/pages/animations/index"),
        meta: {
          title: "番剧",
          icon: "iconfont icon-article",
        },
      },
      {
        name: "Comments",
        path: "comments",
        component: () => import("@/views/pages/comments/index"),
        meta: {
          title: "评论",
          icon: "iconfont icon-comments",
        },
      },
    ],
  },
  {
    path: "/category",
    component: Layout,
    redirect: "/category/nodes",
    name: "cocategoryntent",
    meta: {
      title: "分类管理",
      icon: "iconfont icon-tags",
    },
    children: [
      {
        name: "Nodes",
        path: "nodes",
        component: () => import("@/views/pages/topics/nodes"),
        meta: {
          title: "话题节点",
          icon: "iconfont icon-tag",
        },
      },
      {
        name: "ArticleMenu",
        path: "articleMenu",
        component: () => import("@/views/pages/articles/menu"),
        meta: {
          title: "文章目录",
          icon: "iconfont icon-tag",
        },
      },
      {
        name: "AnimationMenu",
        path: "animationMenu",
        component: () => import("@/views/pages/animations/menu"),
        meta: {
          title: "番剧目录",
          icon: "iconfont icon-tag",
        },
      },
      {
        name: "Tags",
        path: "tags",
        component: () => import("@/views/pages/tags/index"),
        meta: {
          title: "标签",
          icon: "iconfont icon-tags",
        },
      },
    ],
  },
  {
    path: "/order",
    component: Layout,
    redirect: "/order",
    meta: {
      title: "订单管理",
      icon: "iconfont icon-home",
    },
    children: [
      {
        name: "Order",
        path: "index",
        component: () => import("@/views/pages/order/index"),
        meta: {
          title: "订单统计",
          icon: "iconfont icon-home",
        },
      },
      {
        name: "Operate",
        path: "operate",
        component: () => import("@/views/pages/order/operate"),
        meta: {
          title: "订单明细",
          icon: "iconfont icon-home",
        },
      },
      {
        name: "OrderAppeal",
        path: "orderAppeal",
        component: () => import("@/views/pages/order/orderAppeal"),
        meta: {
          title: "未到账申诉",
          icon: "iconfont icon-home",
        },
      },
    ],
  },
  {
    path: "/payConfig",
    component: Layout,
    redirect: "/payConfig",
    children: [
      {
        name: "Pay",
        path: "pay",
        component: () => import("@/views/pages/pay/index"),
        meta: {
          title: "支付配置",
          icon: "iconfont icon-home",
        },
      },
    ],
  },
  {
    path: "/card",
    component: Layout,
    redirect: "/card",
    meta: {
      title: "卡密管理",
      icon: "iconfont icon-home",
    },
    children: [
      {
        name: "Index",
        path: "index",
        component: () => import("@/views/pages/card/index"),
        meta: {
          title: "卡密查询",
          icon: "iconfont icon-home",
        },
      },{
        name: "Export",
        path: "export",
        component: () => import("@/views/pages/card/export"),
        meta: {
          title: "卡密导出",
          icon: "iconfont icon-home",
        },
      },
    ],
  },
  {
    path: "/report",
    component: Layout,
    redirect: "/report",
    children: [
      {
        name: "Report",
        path: "",
        component: () => import("@/views/pages/report/index"),
        meta: {
          title: "举报管理",
          icon: "iconfont icon-home",
        },
      },
    ],
  },
  {
    path: "/spider",
    component: Layout,
    redirect: "/spider",
    // meta: {
    //   title: "爬虫管理",
    //   icon: "iconfont icon-home",
    // },
    children: [
      {
        name: "Animation",
        path: "animation",
        component: () => import("@/views/pages/animations/spider"),
        meta: {
          title: "爬虫管理",
          icon: "iconfont icon-home",
        },
      },
    ],
  },
  {
    path: "/links",
    component: Layout,
    redirect: "/links",
    children: [
      {
        name: "Links",
        path: "",
        component: () => import("@/views/pages/links/index"),
        meta: {
          title: "友情链接",
          icon: "iconfont icon-link",
        },
      },
    ],
  },
  {
    path: "/email",
    component: Layout,
    redirect: "/email",
    children: [
      {
        path: "email",
        component: () => import("@/views/pages/email/index"),
        name: "email",
        meta: {
          title: "邮件",
          icon: "iconfont icon-setting",
        },
      },
    ],
  },
  {
    path: "/settings",
    component: Layout,
    redirect: "/settings",
    children: [
      {
        path: "",
        component: () => import("@/views/pages/settings/index"),
        name: "settings",
        meta: {
          title: "系统设置",
          icon: "iconfont icon-setting",
        },
      },
    ],
  },
];
export default adminRouter;
